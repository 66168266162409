import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import LanguageHelper from 'components/helpers/language-helper';
import ThemeHelper from 'components/helpers/theme-helper.js';
import Page from 'components/page';
import MatchEventsConfigurationStore from 'components/stores/match-events-configuration-store';

export default class ContentPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    LanguageHelper.setLocale(get(props, 'data.contentfulContentPage.node_locale'));
    ThemeHelper.setTheme('mcm');

    MatchEventsConfigurationStore.init(get(props, 'data.contentfulMatchEventsConfiguration.eventsList'));
    const contentfulMatches = get(props, 'data.allContentfulMatch.edges');

    props.data.allContentfulMatch.edges = contentfulMatches.map((match) => {
      if (match.node.awayTeamDisplayName || match.node.homeTeamDisplayName) {
        match.node.homeTeamName = match.node.homeTeamDisplayName
          ? match.node.homeTeamDisplayName
          : match.node.competitorNameHome;
        match.node.awayTeamName = match.node.awayTeamDisplayName
          ? match.node.awayTeamDisplayName
          : match.node.competitorNameAway;
      }
      return match;
    });
  }

  render() {
    return <Page {...this.props} />;
  }
}

export const pageQuery = graphql`
  query ContentPageBy($slug: String!, $sportId: [String]) {
    contentfulContentPage(slug: { eq: $slug }) {
      node_locale
      parentPage {
        ...Breadcrumb
        parentPage {
          ...Breadcrumb
          parentPage {
            ...Breadcrumb
            parentPage {
              ...Breadcrumb
            }
          }
        }
      }

      slug
      subNav {
        ...SubNav
      }
      description
      keywords
      excludePageFromExternalSearch
      excludePageFromInternalSearch
      excludeFromSiteBuild
      showScrollToTopButton
      isMcmNewsPage
      blocks {
        __typename
        ...LoginBlock
        ...TextBlock
        ...LandingBlock
        ...CardBlock
        ...WidgetBlock
        ...CardListBlock
        ...LiveScoreBlock
        ...BetSuggestionBlock
        ...TournamentStandingsBlock
        ...CardLinkBlock
        ...HeroBlock
        ...NewsBlock
        ...McmNewsBlock
        ...OddsComparsionBlock
      }
    }

    contentfulMatchEventsConfiguration {
      eventsList {
        type
        eventType
        periodNames
        title
        hideInEventsList
        pushNotification
        icon {
          file {
            url
          }
        }
        iconOverrideSport_1 {
          file {
            url
          }
        }
        iconOverrideSport_5 {
          file {
            url
          }
        }
        iconOverrideSport_15 {
          file {
            url
          }
        }
        iconOverrideSport_6 {
          file {
            url
          }
        }
        iconOverrideSport_7 {
          file {
            url
          }
        }
      }
    }
    allContentfulMatch(filter: { sportId: { in: $sportId } }, sort: { fields: [scheduled, name], order: ASC }) {
      edges {
        node {
          matchId
          scheduled
          showBetSuggestion
          betType
          betValue
          playableFrom
          competitorNameHome
          competitorIdHome
          homeTeamDisplayName
          competitorNameAway
          competitorIdAway
          awayTeamDisplayName
          sport
          sportId
          tournamentName
          tournamentId
          tvLogo {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
